.header {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 32px 0px;
  border-bottom: 1px solid #fdc9ef90;
  margin: 0 auto;
  width: 90%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: 2b2d42;
  z-index: 5;
  background: inherit;
}
.header_mobile {
  flex-direction: column;
  padding-bottom: 10px;
  align-items: flex-start;
}
