.layout {
  background-color: #2b2d42;
  display: flex;
  flex-direction: column;
  max-width: 1400px;


  margin-top: 80px;
  padding: 28px 0 28px;
  flex-grow: 1;
  z-index: 4;
  scroll-margin-top: 110px;
  position: relative;
}
@media screen and (max-width: 920px) {
  .layout {
    margin-top: 100px;

  }
}
@media screen and (max-width: 600px) {
  .layout {
    margin-top: 40px;
  }
}
