.modal {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0 16px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 15;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  /*transition on close*/
  transition: visibility 0s 0.5s, opacity 0.5s ease-out;
}

.modal_open {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}

.modal__content {
  background-color: white;
  max-width: 430px;
  color: #000;
  width: 100%;
  padding: 36px;
  border-radius: 10px;

  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modal__title {
  margin: 0 0 20px 0;
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
}
.modal__image {
  width: 200px;
  align-self: center;
  margin-bottom: 20px;
}
.modal__status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal__title_sub {
  margin: 16px 0 20px 0;

}
.modal__title_sub:last-of-type {
  margin: 0 0 16px 0;
}
.modal__status_text {
  font-size: 20px;
  text-align: center;
}

.modal__button-close {
  position: absolute;
  top: -30px;
  right: -30px;

  background-image: url("../../images/closebutton.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  width: 27px;
  height: 27px;
  border: none;
  display: block;
  transition: opacity 0.25s ease-in-out;
}

.modal__button-close:hover {
  opacity: 0.6;
}

@media screen and (max-width: 625px) {
  .modal__content {
    max-width: 350px;

    padding: 25px 22px;
  }
  .modal__button-close {
    top: -40px; 
    right: 0;
    width: 20px;
    height: 20px;
  }
  .modal__title {
    font-size: 18px;
  }
}
