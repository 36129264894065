.result {
  margin: 130px auto 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.result__heading-container {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.result__container {
  background-color: rgba(219, 230, 253);
  color: #2b2d42;
  margin: 0 auto;
  padding: 50px;
  border-radius: 100%;
  border: 1px solid #2b2d428d;
  width: 300px;
  height: 300px;
  box-shadow: #2f72e583 5px 5px 55px 5px,
    rgba(255, 192, 203, 0.725) 5px 5px 15px 3px, #2f72e583 -5px -5px 55px 5px,
    rgba(255, 192, 203, 0.725) -5px -5px 15px 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.result__heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 10px 0 30px 0;
  z-index: 0;
}
.result__sub-heading {
  font-size: 24px;
  margin: 30px 0px 0px;
}
.result__heading_card {
  margin: 6px 0px 20px;
  font-size: 28px;
  font-weight: 700;
  z-index: 0;
}
.result__flower {
  position: absolute;
  bottom: 10px;
  right: 150px;
  padding: 0;

  z-index: 0;
}
.result__line {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  z-index: 0;
}
.result__author {
  margin: 0;
}
.result__text {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  z-index: 1;
  text-align: center;
}
.result__text_med {
  font-size: 14px;
  font-weight: 400;
  margin:0;
}

.result__container_button {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 920px) {
  .result {
    margin-top: 150px;
  }
  .result__heading {
    margin: 10px 0 30px;
  }
  .result__heading_card {
    margin: 6px 0px 20px;
  }
}

@media screen and (max-width: 600px) {
  .result {
    margin-top: 100px;
  }

  .result__flower {
    right: 125px;
  }
  .result__sub-heading {
    margin: 50px 0px 10px;
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .result__container {
    border-radius: 8px;
    padding: 20px 20px 30px;
    max-width: 250px;
    height:fit-content;
   
  }
  .result__flower{
    right:70px;
    bottom:10px;
  }
  .result__heading {
    font-size: 28px;
  }
  .result__sub-heading{
    margin: 10px 0px 0px;
  }
  .result__heading-container{
    flex-direction:column;
    align-items: center;
  }
}
@media screen and (max-width: 400px) {
  .result__heading {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .result__container_button {
    padding: 0 50px;
    flex-direction: column-reverse;
  }
}
