.create {
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.create__heading {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0 50px 0;
}
.create__heading_user {
  margin: 20px 0 0;
  color: #fdc9ef;
}

.create__heading_card {
  margin: 0px 0 20px;
  font-size: 28px;
  font-weight: 400px;
  font-weight: 700;
}
.create__card-line {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.create__container {
  background-color: rgba(219, 230, 253);
  color: #2b2d42;
  margin: 0 auto;
  padding: 50px;
  border-radius: 100%;
  border: 1px solid #2b2d428d;
  width: 350px;
  height: 350px;
  box-shadow: #2f72e583 5px 5px 55px 5px,
    rgba(255, 192, 203, 0.725) 5px 5px 15px 3px, #2f72e583 -5px -5px 55px 5px,
    rgba(255, 192, 203, 0.725) -5px -5px 15px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 920px) {
  .create {
    margin-top: 30px;
  }
  .create__heading {
    margin: 0 40px 40px;
  }
  .create__heading_user {
    margin: 0 0 8px;
  }
}

@media screen and (max-width: 600px) {
  .create__container {
    width: 300px;
    height: 300px;
  }

  .create__heading {
    font-size: 28px;
  }
}
@media screen and (max-width: 500px) {
  .create__container {
    border-radius: 8px;
    padding: 30px;
    max-width: 250px;
  }
}
@media screen and (max-width: 400px) {
  .create__heading {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .create__heading_user {
    margin: 0 0 8px;
  }
}
