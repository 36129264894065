.faq {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 130px auto 16px;
  padding: 32px 20px;

  min-height: 70vh;
  z-index: 4;
  scroll-margin-top: 110px;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: rgba(255, 255, 255, 0.97);
  color: #2b2d42;
  box-sizing: border-box;
}

.faq__heading {
  margin: 0 0 16px 0;
  min-width: 0;
}

.faq__questions {
  width: 100%;
  text-align: center;
}
.faq__questions_link {
  text-decoration: none;
  color: #2f71e5;
  font-weight: 700;
}
.faq__questions_link:hover {
  cursor: pointer;
  color: #78125d;
}

@media screen and (max-width: 920px) {
  .faq {
    margin-top: 160px;
    max-width: 700px;
  }
  .faq__heading {
    margin: 0;
   
  }
}
@media screen and (max-width: 800px) {
  .faq {
    max-width: 600px;
    
  }
}
@media screen and (max-width: 700px) {
  .faq {
    max-width: 500px;
  }
  .faq__heading {
   font-size:28px;
   
  }
}
@media screen and (max-width: 600px) {
  .faq {
    margin-top: 100px;

    padding: 28px 20px;
  }
}
