.footer {
  padding: 16px 0;
  border-top: solid 1px rgba(256, 256, 256, 0.3);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 8px;

  width: 100%;
  max-width: 1200px;
}

.footer__text {
  margin: 0;
  font-size: 12px;
  color: rgba(256, 256, 256, 0.5);
  padding-right:4px;
}
.footer__links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  padding-right:4px;
}
.footer__link {
  text-decoration: none;
  color: rgba(256, 256, 256, 0.6);
  font-size: 16px;
}
.footer__link:hover {
  color: #fda6e5;
}
