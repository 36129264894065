.page {
  background-color: #2b2d42;
  margin: 0 auto;
  padding: 0 20px;

  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(256, 256, 256, 0.975);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
}

@media screen and (max-width:400px){
  .page{
    padding: 0 10px;
  }
}